<template>
  <div class="wrapper">
    <div>
      <select
        class="form-control form-control-sm shadow-none col-md-1 d-inline"
        v-model="filter.year.value"
        @change="setFilter"
      >
        <option value="all">все</option>
        <option v-for="(item, idx) in option" :key="idx" :value="item">{{
          item
        }}</option>
      </select>
      <span class="d-inline ml-2 alert alert-primary p-1"
        >{{ all_percent }}% запчастей включены в отчеты</span
      >
    </div>
    <div class="table-responsive">
      <table class="table table-sm" v-if="list_realizations">
        <tr class="hov">
          <th>СЦ</th>
          <th>сумма зч</th>
          <th>%</th>
        </tr>
        <tbody>
          <template v-for="(item, idx) in list_realizations">
            <tr
              :key="idx + 'row'"
              @click="get_one_user(item.id_user)"
              class="hov"
            >
              <td>{{ item.user }}</td>
              <td>{{ item.summ }}</td>
              <td>{{ item.percent }}%</td>
            </tr>
            <tr v-if="visible === item.id_user" :key="idx + 'realiz'">
              <td colspan="3">
                <table class="table table-sm">
                  <template v-for="(item2, idx2) in item.children">
                    <tr
                      :key="idx2 + 'child'"
                      class="hov"
                      @click="
                        get_one_realiz(
                          item2.id,
                          item2.id_user,
                          item2.nomer_nakl,
                          item2.data_nakl_sql
                        )
                      "
                    >
                      <td>{{ item2.nomer_nakl }}</td>
                      <td>{{ item2.data_nakl }}</td>
                      <td>{{ item2.summ }}</td>
                      <td>{{ item2.percent }}%</td>
                    </tr>
                    <tr v-if="visible2 === item2.id" :key="idx2 + 'one'">
                      <td colspan="4">
                        <table class="table table-sm">
                          <tr>
                            <th></th>
                            <th>
                              <span class="d-inline">наименование</span>
                              <span v-if="item2.id_user === '57'" class="pl-2">
                                <select
                                  class="form-control form-control-sm shadow-none col-md-5 d-inline"
                                  v-model="change_user_realiz"
                                  @change="
                                    set_change_user_realiz(
                                      item2.nomer_nakl,
                                      item2.data_nakl_sql
                                    )
                                  "
                                >
                                  <option
                                    v-for="(item,
                                    idx) in change_user_realiz_option"
                                    :key="idx + 'option'"
                                    :value="item.value"
                                    >{{ item.title }}</option
                                  >
                                </select>
                              </span>
                            </th>
                            <th>код</th>
                            <th>артикул</th>
                            <th>шт</th>
                            <th>отч</th>
                            <th>цена</th>
                          </tr>
                          <tr
                            v-for="(item3, idx) in item2.children"
                            :key="idx + 'onec'"
                            class="no-hov"
                          >
                            <td>{{ idx + 1 }}</td>
                            <td>{{ item3.name }}</td>
                            <td>{{ item3.cod }}</td>
                            <td>{{ item3.art }}</td>
                            <td>{{ item3.pcs }}</td>
                            <td>
                              <span
                                class="cursor p-1"
                                @click="otchet('minus', item3)"
                                >-</span
                              >{{ item3.otchet
                              }}<span
                                class="cursor p-1"
                                @click="otchet('plus', item3)"
                                >+</span
                              >
                            </td>
                            <td>{{ item3.cena }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </template>
                </table>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "UploadRealization",
  computed: {
    name: function() {
      let h = this.change_user_realiz_option.filter(
        item => item.value === this.change_user_realiz
      );
      return h[0].title;
    }
  },
  data: () => ({
    filter: { year: { value: new Date().getFullYear(), field: "year" } },
    option: [new Date().getFullYear()],
    list_realizations: null,
    visible: 0,
    visible2: false,
    all_percent: null,
    change_user_realiz: "0",
    change_user_realiz_option: [
      { value: "0", title: "выберете СЦ" },
      { value: "12", title: 'ООО "Дровосек" Казань' },
      { value: "13", title: 'ООО "Дровосек" Киров' },
      { value: "14", title: 'ООО "Дровосек" Нижний Новгород' },
      { value: "15", title: 'ООО "Дровосек" Саратов' },
      { value: "17", title: 'ООО "Дровосек" Ульяновск' },
      { value: "19", title: 'ООО "Дровосек" Йошкар-Ола' },
      { value: "137", title: 'ООО "Дровосек" Энгельс' }
    ]
  }),
  mounted() {
    this.get_list_users_realization();
  },
  methods: {
    get_list_users_realization(id_user, nomer_nakl, data_nakl) {
      HTTPGET({
        url: "/admin/upload_realization/get_list_users_realization.php",
        params: {
          filter: JSON.stringify(this.filter),
          id_user,
          nomer_nakl,
          data_nakl
        }
      }).then(response => {
        this.option = response.data.years;
        this.list_realizations = response.data.realiz;
        this.all_percent = response.data.all_percent;
      });
    },
    setFilter() {
      this.get_list_users_realization();
    },
    get_one_user(id_user) {
      if (this.visible > 0) {
        if (this.visible === id_user) {
          this.visible = 0;
          this.visible2 = false;
        } else {
          this.visible = id_user;
        }
      } else {
        this.visible = id_user;
      }
    },
    get_one_realiz(id, id_user, nomer_nakl, data_nakl) {
      this.change_user_realiz = "0";
      if (!this.visible2) {
        if (this.visible2 === id) {
          this.visible2 = false;
        } else {
          this.get_list_users_realization(id_user, nomer_nakl, data_nakl);
          this.visible2 = id;
        }
      } else {
        this.visible2 = false;
      }
    },
    set_change_user_realiz(nomer_nakl, data_nakl_sql) {
      if (this.name === "выберете СЦ") {
        return;
      }
      if (confirm(`перезаписать накладную на ${this.name}`)) {
        HTTPGET({
          url: "/admin/upload_realization/change_user_realization.php",
          params: {
            nomer_nakl,
            data_nakl_sql,
            id_user: this.change_user_realiz
          }
        }).then(() => this.get_list_users_realization());
      }
    },
    otchet(a, id) {
      HTTPGET({
        url: "/admin/upload_realization/change_otchet_pcs.php",
        params: {
          id: id.id,
          a
        }
      }).then(() =>
        this.get_list_users_realization(id.id_user, id.nomer_nakl, id.data_nakl)
      );
    }
  }
};
</script>
<style lang="scss" scoped></style>
<style scoped>
.wrapper {
  margin: 10px;
}
table {
  margin: 0;
}
.hov:hover > td {
  background: rgba(0, 0, 0, 0.075);
  font-size: 0.7em;
}
.hov > td,
th {
  font-size: 0.7em;
}
.hov > td {
  cursor: pointer;
}
td,
th {
  vertical-align: middle;
}
select {
  margin-bottom: 10px;
}
.no-hov > td {
  font-size: 0.7em;
}
.cursor {
  cursor: pointer;
}
</style>
