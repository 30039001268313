var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter.year.value),expression:"filter.year.value"}],staticClass:"form-control form-control-sm shadow-none col-md-1 d-inline",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.filter.year, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.setFilter]}},[_c('option',{attrs:{"value":"all"}},[_vm._v("все")]),_vm._l((_vm.option),function(item,idx){return _c('option',{key:idx,domProps:{"value":item}},[_vm._v(_vm._s(item))])})],2),_c('span',{staticClass:"d-inline ml-2 alert alert-primary p-1"},[_vm._v(_vm._s(_vm.all_percent)+"% запчастей включены в отчеты")])]),_c('div',{staticClass:"table-responsive"},[(_vm.list_realizations)?_c('table',{staticClass:"table table-sm"},[_vm._m(0),_c('tbody',[_vm._l((_vm.list_realizations),function(item,idx){return [_c('tr',{key:idx + 'row',staticClass:"hov",on:{"click":function($event){return _vm.get_one_user(item.id_user)}}},[_c('td',[_vm._v(_vm._s(item.user))]),_c('td',[_vm._v(_vm._s(item.summ))]),_c('td',[_vm._v(_vm._s(item.percent)+"%")])]),(_vm.visible === item.id_user)?_c('tr',{key:idx + 'realiz'},[_c('td',{attrs:{"colspan":"3"}},[_c('table',{staticClass:"table table-sm"},[_vm._l((item.children),function(item2,idx2){return [_c('tr',{key:idx2 + 'child',staticClass:"hov",on:{"click":function($event){return _vm.get_one_realiz(
                        item2.id,
                        item2.id_user,
                        item2.nomer_nakl,
                        item2.data_nakl_sql
                      )}}},[_c('td',[_vm._v(_vm._s(item2.nomer_nakl))]),_c('td',[_vm._v(_vm._s(item2.data_nakl))]),_c('td',[_vm._v(_vm._s(item2.summ))]),_c('td',[_vm._v(_vm._s(item2.percent)+"%")])]),(_vm.visible2 === item2.id)?_c('tr',{key:idx2 + 'one'},[_c('td',{attrs:{"colspan":"4"}},[_c('table',{staticClass:"table table-sm"},[_c('tr',[_c('th'),_c('th',[_c('span',{staticClass:"d-inline"},[_vm._v("наименование")]),(item2.id_user === '57')?_c('span',{staticClass:"pl-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.change_user_realiz),expression:"change_user_realiz"}],staticClass:"form-control form-control-sm shadow-none col-md-5 d-inline",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.change_user_realiz=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.set_change_user_realiz(
                                    item2.nomer_nakl,
                                    item2.data_nakl_sql
                                  )}]}},_vm._l((_vm.change_user_realiz_option),function(item,idx){return _c('option',{key:idx + 'option',domProps:{"value":item.value}},[_vm._v(_vm._s(item.title))])}),0)]):_vm._e()]),_c('th',[_vm._v("код")]),_c('th',[_vm._v("артикул")]),_c('th',[_vm._v("шт")]),_c('th',[_vm._v("отч")]),_c('th',[_vm._v("цена")])]),_vm._l((item2.children),function(item3,idx){return _c('tr',{key:idx + 'onec',staticClass:"no-hov"},[_c('td',[_vm._v(_vm._s(idx + 1))]),_c('td',[_vm._v(_vm._s(item3.name))]),_c('td',[_vm._v(_vm._s(item3.cod))]),_c('td',[_vm._v(_vm._s(item3.art))]),_c('td',[_vm._v(_vm._s(item3.pcs))]),_c('td',[_c('span',{staticClass:"cursor p-1",on:{"click":function($event){return _vm.otchet('minus', item3)}}},[_vm._v("-")]),_vm._v(_vm._s(item3.otchet)),_c('span',{staticClass:"cursor p-1",on:{"click":function($event){return _vm.otchet('plus', item3)}}},[_vm._v("+")])]),_c('td',[_vm._v(_vm._s(item3.cena))])])})],2)])]):_vm._e()]})],2)])]):_vm._e()]})],2)]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"hov"},[_c('th',[_vm._v("СЦ")]),_c('th',[_vm._v("сумма зч")]),_c('th',[_vm._v("%")])])}]

export { render, staticRenderFns }